<template>
  <BCard body>
    <div class="d-flex justify-between">
      <div class="d-flex items-start">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="font-semibold text-black mb-2 text-2xl">
          Detail Gudangku
        </div>
      </div>
      <div class="">
        <BButton
          variant="outline-primary"
          @click="$router.push('/ajukan-inbound')"
        >
          Ajukan Inbound
        </BButton>
      </div>
    </div>
    <div class="border p-2 mt-2">
      <div class="d-flex justify-between">
        <div class="d-flex flex-row items-center">
          <div class="mr-1">
            <b-avatar
              :src="detail.image_logo_url"
            />
          </div>
          <div class="d-flex flex-column items-start text-black">
            <div class="font-medium">
              {{ detail.name }}
            </div>
            <div class="">
              {{ detail.address }}
            </div>
          </div>
        </div>
        <button
          class="d-flex space-x-2 items-center outline-none"
          @click="handleIsDefault()"
        >
          <img
            class="w-5"
            :src="handleUtama(detail.is_default)"
          >
          <span class="text-black">Jadikan sebagai gudang utama</span>
        </button>
      </div>
      <div class="mt-2 d-flex text-black space-x-10">
        <div class="">
          Owner
        </div>
        <div class="d-flex space-x-2">
          <span>{{ detail.owner }}</span>
        </div>
      </div>
      <div class="d-flex justify-between">
        <div class="mt-1 d-flex text-black space-x-10">
          <div class="">
            Status
          </div>
          <div class="">
            {{ detail.status }}
          </div>
        </div>
        <div class="space-x-3">
          <BButton
            variant="outline-primary"
            @click="handleListInbound(detail.id)"
          >
            List Inbound
          </BButton>
          <BButton
            variant="outline-primary"
            @click="handleInfoGudang($route.params.id)"
          >
            Lihat Info Gudang
          </BButton>
          <BButton
            variant="primary"
            @click="chatButton(detail.pic_phone)"
          >
            <template>
              <div class="d-flex space-x-3">
                <img
                  class="w-4"
                  src="https://storage.googleapis.com/komerce/assets/svg/chat.svg"
                >
                <span>Chat</span>
              </div>
            </template>
          </BButton>
          <b-popover
            target="penarikanBarang"
            variant="dark"
            triggers="hover"
            placement="top"
            boundary="viewport"
          >
            Penarikan Barang
          </b-popover>
          <BButton
            id="penarikanBarang"
            style="padding: 7px;"
            variant="outline-primary"
            :disabled="penarikan"
            @click="handlePenarikanButton()"
          >
            <img
              class="w-6"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/directbox-send.svg"
            >
          </BButton>
        </div>
      </div>
    </div>
    <div class="border mt-2">
      <div class="p-2 d-flex justify-between items-center">
        <div class="text-black font-semibold">
          Stok Produk
        </div>
        <b-button
          variant="outline-primary"
          @click="handleTambahProduk($route.params.id, detail.id)"
        >
          Tambah Produk
        </b-button>
      </div>
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          :items="products"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(nama_produk)="data">
            <div class="d-flex items-start gap-2 text-black">
              <b-img
                :src="data.item.image_path ? data.item.image_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                fluid
                class="image-product"
              />
              <div class="d-block">
                <div class="font-medium">
                  {{ data.item.product_name }}
                </div>
                <div class="text-primary text-[12px]">
                  SKU : {{ data.item.sku }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(variasi)="data">
            <div v-if="data.item.is_variant !== 0">
              <div
                class="mb-50"
              >
                <span v-b-tooltip.hover.top="data.item.variant[0].variant_name.length >= 25 ? data.item.variant[0].variant_name : null">{{ truncateWithEllipsis(data.item.variant[0].variant_name) }}</span>
              </div>
              <BCollapse :id="`collapse-${String(data.index)}`">
                <div
                  v-for="(item, idx) in data.item.variant.slice(1)"
                  :key="idx"
                  class="mb-50"
                >
                  <span v-b-tooltip.hover.top="item.variant_name.length >= 25 ? item.variant_name : null">
                    {{ truncateWithEllipsis(item.variant_name) }}
                  </span>
                </div>
              </BCollapse>
            </div>
            <div
              v-else
              class="mt-1"
            >
              -
            </div>
            <div
              v-if="data.item.variant.length > 1"
              v-b-toggle="`collapse-${String(data.index)}`"
              variant="flat-dark"
              size="sm"
              class="mt-1 flex items-center justify-end"
            >
              <span
                class="when-opened"
              >
                Tutup
              </span>
              <span
                class="when-closed"
              >
                Tampilkan Variasi
              </span>
              <feather-icon
                icon="ChevronDownIcon"
                class="when-closed"
              />
              <feather-icon
                icon="ChevronUpIcon"
                class="when-opened"
              />
            </div>
          </template>
          <template #cell(stok)="data">
            <div v-if="data.item.is_variant !== 0">
              <div
                class="mb-50"
              >
                <span>{{ data.item.variant[0].stock }}</span>
              </div>
              <BCollapse :id="`collapse-${String(data.index)}`">
                <div
                  v-for="(item, idx) in data.item.variant.slice(1)"
                  :key="idx"
                  class="mb-50"
                >
                  {{ item.stock }}
                </div>
              </BCollapse>
            </div>
            <div
              v-else
              class="mt-1"
            >
              {{ data.item.stock }}
            </div>
          </template>
          <template #cell(biaya)="data">
            <div class="">
              {{ formatRupiah(data.item.fulfillment_cost) }}
            </div>
          </template>
          <template #head(biaya_tambahan)="data">
            <div class="d-flex align-items-center">
              {{ data.label }}
              <div class="ml-[11px]">
                <img
                  id="addonPopover"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                >
                <b-popover
                  triggers="hover"
                  target="addonPopover"
                  placement="top"
                >
                  Biaya fullfilement untuk penambahan quantity dari suatu barang dalam 1 resi
                </b-popover>
              </div>
            </div>
          </template>
          <template #cell(biaya_tambahan)="data">
            <div class="">
              {{ formatRupiah(data.item.addon_cost) }} / {{ data.item.addon_minimum }} Pcs
            </div>
          </template>
          <template #row-details="data">
            <b-row
              class="pl-[4.1rem] -mt-[1rem]"
            >
              <b-col cols="12">
                <div class="flex">
                  <div
                    v-b-toggle="`bundling-${String(data.index)}`"
                    class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                      alt="bundling"
                      width="16"
                    >
                    <div>Bundling</div>
                  </div>
                </div>
                <b-collapse
                  :id="`bundling-${String(data.index)}`"
                >
                  <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                    <div class="border rounded-t w-[80%]">
                      <div class="bg-[#FFECE9] py-[8px] px-[28px] rounded-t text-black font-[500]">
                        Produk
                      </div>
                      <b-table
                        :items="data.item.bundle_component"
                        :fields="fieldsBundling"
                      >
                        <template #cell(no)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template #cell(product_name)="data">
                          <div class="flex gap-[16px] items-start">
                            <img
                              :src="data.item.product_image[0] ? data.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                              alt="product"
                              width="52"
                              height="52"
                            >
                            <div class="space-y-[4px]">
                              <div class="font-medium">
                                {{ data.item.product_name }}
                              </div>
                              <div class="text-primary text-[12px]">
                                SKU: {{ data.item.sku }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div class="flex ml-[0.8rem]">
                    <div
                      v-b-toggle="`bundling-${String(data.index)}`"
                      class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                    >
                      <feather-icon
                        icon="ChevronUpIcon"
                      />
                      <div>Tutup</div>
                    </div>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
          </template>
        </BTable>
      </BOverlay>
    </div>
  </BCard>
</template>

<script>
import chatButton from '@/libs/chatButton'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      chatButton,
      loading: false,

      penarikan: true,

      detail: {},

      fields: [
        {
          key: 'nama_produk',
          label: 'Nama Produk',
          thClass: 'text-black text-capitalize py-1 font-semibold',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black',
        },
        {
          key: 'variasi',
          label: 'Variasi',
          thClass: 'text-black text-capitalize py-1 font-semibold',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'w-[250px] text-black align-top',
        },
        {
          key: 'stok',
          label: 'Stok Produk',
          thClass: 'text-black text-capitalize py-1 font-semibold text-center',
          class: 'bg-white text-center',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'biaya',
          label: 'Biaya Fulfillment',
          thClass: 'text-black text-capitalize py-1 font-semibold text-center',
          class: 'bg-white text-center',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black text-center',
        },
        {
          key: 'biaya_tambahan',
          label: 'Biaya Tambahan',
          thClass: 'text-black text-capitalize py-1 font-semibold text-center',
          class: 'bg-white text-center',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black text-center',
        },
      ],

      fieldsBundling: [
        {
          key: 'no',
          label: 'No',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-black',
        },
        {
          key: 'product_name',
          label: 'Nama Produk',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-black',
        },
        {
          key: 'qty',
          label: 'Jumlah',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
          tdClass: 'text-black text-center',
        },
      ],

      products: [],
    }
  },

  created() {
    this.fetchDetailGudangKompack()
  },

  methods: {
    handlePenarikanButton() {
      this.$router.push({
        path: '/penarikan-barang',
      })
    },
    handleTambahProduk(id, whId) {
      this.$router.push({
        path: `/tambah-produk-gudang-kompack/${id}`,
      })
      localStorage.setItem('warehouse_id', whId)
    },
    handleListInbound(id) {
      this.$router.push({
        path: '/riwayat-pengajuan',
      })
      localStorage.setItem('warehouse_id', JSON.stringify(id))
    },
    handleInfoGudang(id) {
      this.$router.push({
        path: `/search-gudang/detail/${id}`,
      })
    },
    handleUtama(value) {
      if (value === 1) return 'https://storage.googleapis.com/komerce/assets/svg/gudang-utama-orange.svg'
      return 'https://storage.googleapis.com/komerce/assets/svg/gudang-utama.svg'
    },
    handleIsDefault() {
      this.$http_komship.put(`/v2/komship/warehouse/detail/${this.$route.params.id}/set-default`)
        .then(
          setTimeout(() => {
            this.fetchDetailGudangKompack()
          }, 100),
        ).catch(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal jadikan gudang utama, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    async fetchDetailGudangKompack() {
      this.loading = true
      await this.$http_komship.get(`/v1/komship/warehouse/detail/${this.$route.params.id}/partner-warehouse`)
        .then(response => {
          this.detail = response.data.data

          const { product } = response.data.data

          this.products = product.map(item => ({
            ...item,
            _showDetails: item.is_bundling === 1,
          }))

          this.loading = false
          this.penarikan = false
        }).catch(() => {
          this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    formatRupiah(value) {
      return `Rp ${new Intl.NumberFormat('id-ID', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(value)}`
    },
    truncateWithEllipsis(text) {
      return text.length <= 25 ? text : `${text.slice(0, 25)}...`
    },
  },
}
</script>

<style scoped>
.collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
.image-product{
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}
.verified-badge {
  background-color: #DFF3FF;
  display: flex;
  align-items: center;
  color: #08A0F7;
  padding: 5px 10px;
  border-radius: 4px;
 }
</style>
